<template>
  <div class="cos-nav-none">
    <NuxtLink v-if="href" :class="[buttonStyle]" :href="href" :target="target" :aria-label="label ?? content" :rel="rel">
      <div :class="[buttonStyle]">
        <slot>{{ content }}</slot>
      </div>
    </NuxtLink>
    <button v-else-if="button" :class="[buttonStyle]" :aria-label="label ?? content" :type="buttonType">
      <slot>{{ content }}</slot>
    </button>
    <div v-else :class="[buttonStyle]">
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  label?: string,
  button?: boolean,
  buttonType?: 'button' | 'submit' | 'reset'
  content?: string,
  design?: 'Custom' | 'Dark' | 'Light' | 'Teal' | 'Luxus2' | 'Luxus3' | 'Luxus4' | 'Luxus5' | 'Luxus' | 'Gray' | 'Orange' | 'Cruise'
  href?: string,
  rel?: string,
  target?: '_blank' | '_self' | '_top' | '_parent',
}

const props = withDefaults(defineProps<Props>(), {
  buttonType: 'button',
  content: 'Mehr erfahren',
  design: 'Dark',
  href: undefined,
  label: undefined,
  rel: undefined,
  target: '_self',
})

const defaultPadding = 'py-1.5 sm:py-2.5 px-5'
const defaultStyle = 'border-none inline-block text-center tracking-wider uppercase no-underline hover:border-none'
const tightPadding = 'py-1.5 px-5'
const styleMap = {
  'Custom': '',
  'Dark': [defaultPadding, defaultStyle, 'bg-[#241f1f] text-white hover:bg-[#241f1f] hover:text-white 1duration-700'].join(' '),
  'Light': [defaultPadding, defaultStyle, 'bg-white text-[#333] hover:bg-white hover:text-[#333]'].join(' '),
  'Teal': [defaultPadding, defaultStyle, 'bg-teal-600 text-white hover:bg-teal-600 hover:text-whiteounded'].join(' '),
  'Orange': [defaultPadding, defaultStyle, 'bg-orange-500 text-white hover:bg-orange-500 hover:text-white'].join(' '),
  'Luxus2': [defaultPadding, defaultStyle, 'bg-lux-400 text-white hover:bg-lux-400 hover:text-white'].join(' '),
  'Luxus3': [defaultPadding, defaultStyle, 'bg-[#A19581] text-white hover:bg-[#A19581] hover:text-white'].join(' '),
  'Luxus4': [defaultPadding, defaultStyle, 'bg-mongoose-400 text-white hover:bg-mongoose-500 hover:text-white'].join(' '),
  'Luxus5': [defaultPadding, defaultStyle, 'bg-[#847164] text-white hover:bg-[#847164] hover:text-white'].join(' '),
  'Cruise': [tightPadding, defaultStyle, 'justify-end bg-amber-500 hover:bg-orange-500 items-end duration-500 text-white hover:text-white'].join(' '),
  'Luxus': [defaultPadding, defaultStyle, 'bg-lux-100 text-white shadow hover:bg-lux-400 hover:text-white hover:shadow-lg ease-linear duration-150'].join(' '),
  'Gray': [defaultPadding, defaultStyle, 'bg-gray-700 text-white shadow hover:bg-gray-600 hover:text-white hover:shadow-lg ease-linear duration-150'].join(' '),
}
const buttonStyle = styleMap[props.design]
</script>
